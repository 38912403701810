.app_security_popover__content {
  text-align: center;
  max-height: 300px;
  padding: 20px 35px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.app_security_popover__content button {
  margin-top: 40px;
}

.app_security_popover_hero {
  height: 200px;
  max-width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
