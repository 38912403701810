.app_aside__hero {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.app_aside__meta__item {
  padding: 8px 0;
}

.app_aside__meta__title {
  margin-bottom: 4px;
}

.app_aside__meta__value {
  color: #727272;
}

.app_side__list li {
  margin-bottom: 12px;
}

.app_side__list p {
  margin-bottom: 2px;
}

.app_side__list ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 12px;
}

.app_side__list img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.app_side__list li {
  margin-bottom: 15px;
}

.app_side__list li:last-child {
  margin-bottom: 0;
}

.app_side__list .h4 {
  margin-bottom: 15px;
}

.app_aside__map {
  width: 100%;
  height: 200px;
  display: block;
  margin-bottom: 30px;
}
