.simple_card {
  cursor: pointer;
  padding-top: 14px;
  padding-bottom: 14px;
}

.simple_card img {
  top: 1px;
  width: 17px;
  float: left;
  height: 17px;
  position: relative;
  margin-right: 10px;
}

img.alignRight {
  float: right;
}

.simple_card p {
  margin-bottom: 0;
}
