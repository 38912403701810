.app__navbar {
  top: 0;
  z-index: 16;
  width: 100%;
  position: fixed;
  background: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
}

.app__navbar__search {
  width: 100%;
}

.download-modal-btn {
  padding: 0;
}

.app_navigation_popover {
  min-width: 330px;
}

.app__navbar__search_input {
  border: none;
  max-width: 300px;
}

.app_master_search_icon {
  top: 5px;
  font-size: 28px;
  position: relative;
}

.search_results_container {
  padding: 30px;
  max-height: 421px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .search_results_container {
    padding: 15px 0;
  }
}
