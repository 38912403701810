.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}
