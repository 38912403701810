.app_list {
  padding-left: 0;
  list-style: none;
}

.app_list li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.app_list__item {
  display: 'flex';
  align-items: 'center';
}
