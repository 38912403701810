.searchbox {
  margin-bottom: 15px;
}

.results__list {
  padding-left: 0;
  list-style: none;
  overflow-y: scroll;
}

.results__list li {
  margin-bottom: 20px;
}
