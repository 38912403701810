.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_event_host__name,
.page_event_host__avatar {
  display: inline-block;
}

.page_event_host__name {
  margin-left: 15px;
}

.page_event__hero__wrapper {
  height: 250px;
  margin-bottom: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.add_to_calendar {
  margin-bottom: 20px;
}

header {
  margin-bottom: 30px;
}
