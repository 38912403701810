.modal__close {
  top: 14px;
  left: 10px;
  border: none;
  color: #ffffff;
  box-shadow: none;
  position: absolute;
  background: transparent;
}

.modal__footer {
  margin: 0 auto;
  border-top: none;
  text-align: center;
  padding: 40px 0;
}

.moda__header_bs {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal__header {
  width: 100%;
  max-height: 250px;
  position: relative;
  text-align: center;
}

.modal__body {
  text-align: center;
}

.modal__footer_badge_android {
  height: 58px;
}

.modal__footer_badge_ios {
  height: 40px;
}
