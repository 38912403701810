.searchbox {
  margin-bottom: 15px;
}

.hr {
  margin: 0;
  border-top: 1px solid #f0f0f0;
}

.anchor,
.anchor:hover {
  color: #212121;
}
