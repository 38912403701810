.card {
  height: 81px;
  cursor: pointer;
  font-size: 0.9em;
  overflow: hidden;
}

.meta {
  display: flex;
  font-size: 0.9em;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.name {
  font-size: 0.9em;
  margin-bottom: 6px;
}

.type {
  color: #666666;
}

.short_name {
  color: #4a4a4a;
}

.type,
.short_name {
  font-size: 0.9em;
  margin-bottom: 4px;
}

.image_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image_wrapper p:last-child {
  margin-bottom: 0;
}

.badge {
  color: #fff;
  min-width: 55px;
  font-size: 0.9em;
  padding: 2px 5px;
  text-align: center;
  border-radius: 12px;
}

.open {
  background: #07c86c;
}

.closed {
  background: #ff3d4d;
}

.opening_soon {
  background: #ec6634;
}

.hours {
  display: flex;
  font-size: 0.8em;
  align-items: self-end;
  flex-direction: column;
  justify-content: space-around;
}

.time {
  font-size: 0.9em;
  margin-bottom: 0;
  text-align: right;
}

.image {
  width: 45px;
  height: 45px;
  padding: 5px;
  object-fit: cover;
  max-width: 55.33px;
}

.image_bg {
  margin-right: 8px;
  border-radius: 6px;
  background: #f2f2f2;
}
