.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_deal_host__name,
.page_deal_host__avatar {
  display: inline-block;
}

.page_deal_host__name {
  margin-left: 15px;
}

.page_deal__hero__wrapper {
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;
}

header {
  margin-bottom: 30px;
}
