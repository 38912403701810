.user__content {
  text-align: center;
}

.user__content__main {
  font-size: 2.25em;
  font-weight: 700;
}
@media (min-width: 460px) {
  .user__content__main {
    font-size: 3em;
  }
}

.user__content__icon i {
  font-size: 8em;
}
