@import 'styles/variables';

.user__navbar {
  padding: 1em 0;
  margin: 0 1em 3em 1em;
  border-bottom: 1px solid $color_offwhite;
}

.user__navbar__image {
  width: 4em;
  margin: auto;
  list-style-type: none;
}

.user__navbar__image img {
  width: 100%;
  border-radius: 10px;
}
