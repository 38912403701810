/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  top: -30px;
  left: 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 200px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.popup-label {
  top: 9px;
  left: -10px;
  z-index: -1;
  max-width: 160px;
  font-size: 1.1em;
  padding: 4px 6px;
  border-radius: 4px;
  padding-left: 14px;
  position: relative;
  background: white;
  display: inline-block;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
}

.popup-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  /* border: 2px solid white; */
}

.popup-container {
  height: 0;
  cursor: auto;
  position: absolute;
}
