.app_avatar__round {
  border-radius: 50%;
}

.app_avatar__small {
  width: 27px;
  height: 27px;
}

.app_avatar__medium {
  width: 40px;
  height: 40px;
}

.app_avatar__large {
  width: 60px;
  height: 60px;
}
