@import 'styles/variables';

.breadcrumb {
  margin-bottom: 0;
  background: transparent;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.app_breadcrumb {
  margin: 10px 0;
  background: none;
}

.app_breadcrumb__border {
  border-bottom: 1px solid #e9e9e9;
}

.app_breadcrumb_link {
  text-transform: capitalize;
}

.app_breadcrumb_item {
  color: $color_primary;
}
