@import 'styles/variables';

.app_nav_links {
  word-break: normal;
  word-wrap: normal;
}

.app_nav_links i {
  color: $color_black;
}

.app_nav_links a.active {
  padding-bottom: 3px;
  border-bottom: 2px solid;
}

.app_nav_links__item {
  margin: 0 15px;
}

.app_nav_links__item:last-child {
  margin-right: 0;
}

.app_nav_links__item .dropdown-menu {
  padding: 0;
}

@media (max-width: 767px) {
  .app_nav_links__item {
    margin: 0;
  }
}
