@import 'styles/variables';

.campus_guide_page {
  margin-top: 30px;
}

.card {
  margin-bottom: 15px;
}

.welcome__message {
  margin-bottom: 40px;
}

.welcome__message__btn {
  font-size: $font_20px;
  padding-bottom: 4px;
  border-bottom: 1px solid;
}

.campus_guide_page main {
  margin-bottom: 15px;
}

.campus_guide_page main strong {
  display: block;
  margin-bottom: 3px;
  color: $color_secondary;
}

.category__name {
  margin-bottom: 15px;
}

.category__name p {
  text-transform: uppercase;
}

.card:hover {
  cursor: pointer;
}

.category {
  padding: 15px 0;
}
