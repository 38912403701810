body {
  background: #fff;

  &.overflowHidden {
    height: 100%;
    overflow: hidden;
  }
}

.app__content {
  padding-top: 90px;
}

body,
html,
#root,
.app {
  height: 100%;
}

.page-padding-top {
  padding-top: 15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.no_scroll_bar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent;
  }
}
