.item__avatar {
  height: 80px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media all and (max-width: 767px) {
  .item__avatar {
    height: 40px;
    width: 100%;
  }
}
