@import 'styles/variables';

body {
  line-height: 1.4;
  color: $color_black;
  font-size: $font_16px;
  font-family: $family_regular;
  font-weight: $weight_regular;
  word-break: break-word;
  word-wrap: break-word;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.muted {
  color: $color_secondary;
}

.small {
  font-size: $font_small;
}

.render_line_breaks {
  white-space: pre-wrap;
}

h1,
.h1 {
  font-size: $font_36px;
}

h2,
.h2 {
  font-size: $font_20px;
}

h3,
.h3 {
  font-size: $font_20px;
}

h4,
.h4 {
  font-size: $font_20px;
}

.strong,
strong {
  font-weight: $weight_bold;
  font-family: $family_bold;
}

.regular {
  font-weight: $weight_regular;
  font-family: $family_regular;
}

a,
.link {
  color: $color_primary;
  transition: color 300ms ease-in-out;
}
a:hover,
.link:hover {
  color: $color_primary_hover;
  text-decoration: none;
}

.link--secondary {
  color: $color_secondary;
}

.link--text {
  color: $color_black;
}

.link--accent {
  color: $color_primary;
}
