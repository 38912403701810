.navbar_announcements {
  padding: 10px 20px;
}

.navbar_announcements_results {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar_announcements_result__item {
  padding: 13px 0;
  border-bottom: 1px solid #f2f2f2;
}

.navbar_announcements_result__item p {
  margin-bottom: 3px;
}

.navbar_announcements_result__item:hover {
  cursor: pointer;
}

.navbar_announcements_result__item__image {
  width: 50px;
  height: 50px;
}
