@font-face {
  font-family: 'oohlala_bold';
  src: url(/dist/static/media/Gibson-SemiBold.07107681.woff2) format('woff2'),
    url(/dist/static/media/Gibson-SemiBold.24ace708.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'oohlala_regular';
  src: url(/dist/static/media/Gibson-Regular.4b0ebb97.woff2) format('woff2'),
    url(/dist/static/media/Gibson-Regular.8507241d.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/dist/static/media/MaterialIcons-Regular.e79bfd88.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(/dist/static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
    url(/dist/static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
    url(/dist/static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 300ms ease-in-out; }

.button--animate:hover {
  box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.3); }

.button--info {
  color: #ffffff;
  background: #17a2b8; }

.button--primary {
  color: #ffffff;
  background: #0076ff; }

.button--accent {
  color: #ffffff; }

.button--danger {
  color: #ffffff;
  background: #f5a623; }

.button--warning {
  color: #ffffff;
  background: #ff0000; }

.button--success {
  color: #ffffff;
  background: #6ec537; }

.button--transparent {
  color: #212121;
  background: transparent; }

.button--block {
  width: 100%;
  display: block; }

.button--border {
  border: 1px solid; }

.button--hover:hover {
  cursor: pointer; }

.button--disabled {
  display: block; }

.button--no-padding {
  padding: 0; }

.button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none; }
  .button-reset:focus, .button-reset:active {
    outline: none; }

body {
  background: #fff; }
  body.overflowHidden {
    height: 100%;
    overflow: hidden; }

.app__content {
  padding-top: 90px; }

body,
html,
#root,
.app {
  height: 100%; }

.page-padding-top {
  padding-top: 15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.no_scroll_bar {
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .no_scroll_bar::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; }

.modal-content {
  border: none;
  border-radius: 0; }

body {
  line-height: 1.4;
  color: #212121;
  font-size: 1em;
  font-family: "oohlala_regular";
  font-weight: 400;
  word-break: break-word;
  word-wrap: break-word; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.muted {
  color: #8e8e8e; }

.small {
  font-size: 0.9em; }

.render_line_breaks {
  white-space: pre-wrap; }

h1,
.h1 {
  font-size: 2.25em; }

h2,
.h2 {
  font-size: 1.25em; }

h3,
.h3 {
  font-size: 1.25em; }

h4,
.h4 {
  font-size: 1.25em; }

.strong,
strong {
  font-weight: 600;
  font-family: "oohlala_bold"; }

.regular {
  font-weight: 400;
  font-family: "oohlala_regular"; }

a,
.link {
  color: #0076ff;
  transition: color 300ms ease-in-out; }

a:hover,
.link:hover {
  color: #0085c4;
  text-decoration: none; }

.link--secondary {
  color: #8e8e8e; }

.link--text {
  color: #212121; }

.link--accent {
  color: #0076ff; }

.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.app_list {
  padding-left: 0;
  list-style: none;
}

.app_list li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.app_list__item {
  display: 'flex';
  -webkit-align-items: 'center';
          align-items: 'center';
}

.item__avatar {
  height: 80px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media all and (max-width: 767px) {
  .item__avatar {
    height: 40px;
    width: 100%;
  }
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

.spinner.blocked {
  display: block;
  margin: 40px auto;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.AppSearch_wrapper__2IGJx {
  display: -webkit-flex;
  display: flex;
  padding: 8px 14px;
  background: #fff;
  border-radius: 6px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }

.AppSearch_wrapper__2IGJx span {
  -webkit-flex: 1 1;
          flex: 1 1; }

input {
  border: none;
  color: #4d4d4d;
  width: calc(100% - 24px); }
  input:focus, input:active {
    outline: none; }

.app_no_results {
  text-align: center;
}

.app_no_results i {
  margin-bottom: 15px;
}

.app_pagination {
  margin-top: 40px;
  text-align: center;
}

.app_pagination button {
  border: none;
  box-shadow: none;
  background: transparent;
}

.app_pagination button:not(:disabled):hover {
  cursor: pointer;
}

.app_pagination span {
  top: -6px;
  position: relative;
}

.meta img {
  width: 720;
  height: 240; }

.description {
  margin-top: 30px; }

.page_event_host__name,
.page_event_host__avatar {
  display: inline-block; }

.page_event_host__name {
  margin-left: 15px; }

.page_event__hero__wrapper {
  height: 250px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px; }

.add_to_calendar {
  margin-bottom: 20px; }

header {
  margin-bottom: 30px; }

div.divider {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #e0e0e0; }

.app_aside__hero {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.app_aside__meta__item {
  padding: 8px 0;
}

.app_aside__meta__title {
  margin-bottom: 4px;
}

.app_aside__meta__value {
  color: #727272;
}

.app_side__list li {
  margin-bottom: 12px;
}

.app_side__list p {
  margin-bottom: 2px;
}

.app_side__list ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 12px;
}

.app_side__list img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.app_side__list li {
  margin-bottom: 15px;
}

.app_side__list li:last-child {
  margin-bottom: 0;
}

.app_side__list .h4 {
  margin-bottom: 15px;
}

.app_aside__map {
  width: 100%;
  height: 200px;
  display: block;
  margin-bottom: 30px;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  top: -30px;
  left: 0;
  padding: 0;
  position: absolute;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 200px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.popup-label {
  top: 9px;
  left: -10px;
  z-index: -1;
  max-width: 160px;
  font-size: 1.1em;
  padding: 4px 6px;
  border-radius: 4px;
  padding-left: 14px;
  position: relative;
  background: white;
  display: inline-block;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
}

.popup-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  /* border: 2px solid white; */
}

.popup-container {
  height: 0;
  cursor: auto;
  position: absolute;
}

.modal__close {
  top: 14px;
  left: 10px;
  border: none;
  color: #ffffff;
  box-shadow: none;
  position: absolute;
  background: transparent;
}

.modal__footer {
  margin: 0 auto;
  border-top: none;
  text-align: center;
  padding: 40px 0;
}

.moda__header_bs {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal__header {
  width: 100%;
  max-height: 250px;
  position: relative;
  text-align: center;
}

.modal__body {
  text-align: center;
}

.modal__footer_badge_android {
  height: 58px;
}

.modal__footer_badge_ios {
  height: 40px;
}

.AppParagraph_app_paragraph__2_0S9 {
  white-space: pre-wrap; }

.AppParagraph_app_paragraph__2_0S9 a {
  color: #0076ff;
  background-color: transparent;
  text-decoration: underline; }

.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_deal_host__name,
.page_deal_host__avatar {
  display: inline-block;
}

.page_deal_host__name {
  margin-left: 15px;
}

.page_deal__hero__wrapper {
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;
}

header {
  margin-bottom: 30px;
}

.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_deal_host__name,
.page_deal_host__avatar {
  display: inline-block;
}

.page_deal_host__name {
  margin-left: 15px;
}

.page_deal__hero__wrapper {
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;
}

header {
  margin-bottom: 30px;
}

.app_avatar__round {
  border-radius: 50%;
}

.app_avatar__small {
  width: 27px;
  height: 27px;
}

.app_avatar__medium {
  width: 40px;
  height: 40px;
}

.app_avatar__large {
  width: 60px;
  height: 60px;
}


.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_event_host__name,
.page_event_host__avatar {
  display: inline-block;
}

.page_event_host__name {
  margin-left: 15px;
}

.page_event__hero__wrapper {
  height: 250px;
  margin-bottom: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.add_to_calendar {
  margin-bottom: 20px;
}

header {
  margin-bottom: 30px;
}


.description {
  margin-top: 30px;
}

.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.description {
  margin-top: 30px;
}


.title {
  text-align: center;
}

.search {
  padding: 15px 0;
}

.no_results {
  margin-top: 120px;
}

.meta__links {
  list-style: none;
  padding-left: 0;
}

.meta__title {
  margin-top: 30px;
  margin-bottom: 10px;
}

.carousel-indicators li {
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 50%; }

.carousel_img_wrapper {
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.app_carousel_indicator {
  height: 35px;
  background: #212121;
  margin-left: 0;
  margin-right: 0;
  bottom: 0;
  margin-bottom: 0; }

.app__navbar {
  top: 0;
  z-index: 16;
  width: 100%;
  position: fixed;
  background: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
}

.app__navbar__search {
  width: 100%;
}

.download-modal-btn {
  padding: 0;
}

.app_navigation_popover {
  min-width: 330px;
}

.app__navbar__search_input {
  border: none;
  max-width: 300px;
}

.app_master_search_icon {
  top: 5px;
  font-size: 28px;
  position: relative;
}

.search_results_container {
  padding: 30px;
  max-height: 421px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .search_results_container {
    padding: 15px 0;
  }
}

.app_nav_links {
  word-break: normal;
  word-wrap: normal; }

.app_nav_links i {
  color: #212121; }

.app_nav_links a.active {
  padding-bottom: 3px;
  border-bottom: 2px solid; }

.app_nav_links__item {
  margin: 0 15px; }

.app_nav_links__item:last-child {
  margin-right: 0; }

.app_nav_links__item .dropdown-menu {
  padding: 0; }

@media (max-width: 767px) {
  .app_nav_links__item {
    margin: 0; } }

.app_security_popover__content {
  text-align: center;
  max-height: 300px;
  padding: 20px 35px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.app_security_popover__content button {
  margin-top: 40px;
}

.app_security_popover_hero {
  height: 200px;
  max-width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.navbar_announcements {
  padding: 10px 20px;
}

.navbar_announcements_results {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar_announcements_result__item {
  padding: 13px 0;
  border-bottom: 1px solid #f2f2f2;
}

.navbar_announcements_result__item p {
  margin-bottom: 3px;
}

.navbar_announcements_result__item:hover {
  cursor: pointer;
}

.navbar_announcements_result__item__image {
  width: 50px;
  height: 50px;
}

.announcement__header {
  width: 100%;
  height: 130px;
  position: relative;
  text-align: center;
  background: #0076ff; }

.announcement__header img {
  top: 10px;
  position: relative; }

.announcement__close {
  top: 14px;
  left: 10px;
  border: none;
  color: #ffffff;
  box-shadow: none;
  position: absolute;
  background: transparent; }

input.navbar__search {
  border: none;
  width: 100%;
}

.app_master_search_icon,
input.navbar__search::-webkit-input-placeholder {
  color: #bdbdbd;
}

.app_master_search_icon,
input.navbar__search::-ms-input-placeholder {
  color: #bdbdbd;
}

.app_master_search_icon,
input.navbar__search::placeholder {
  color: #bdbdbd;
}

input.navbar__search:focus {
  box-shadow: none;
  border-color: transparent;
}

.CampusMapList_searchbox__3irEf {
  margin-bottom: 15px;
}

.MetaCard_meta_card__1nPds img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  margin-bottom: 12px;
}

.MetaCard_meta_card__1nPds p {
  color: #333333;
}

.NoResults_wrapper__3q5ae {
  text-align: center;
  margin: 70px auto 30px auto; }

.NoResults_img_wrapper__mv9KR {
  width: 120px;
  height: 120px;
  display: -webkit-flex;
  display: flex;
  border-radius: 50%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f4f4f4; }
  .NoResults_img_wrapper__mv9KR img {
    width: 40px; }

.NoResults_text__30mAv {
  color: #d4d4d4;
  margin-top: 10px; }

.HoursCard_hours_card__UPxMI {
  padding-top: 14px; }

.HoursCard_schedules__2oZH6 {
  padding-left: 0;
  list-style: none;
  font-size: 0.96em; }

.HoursCard_dining_list_item__3yHy9:not(:last-child) {
  margin-bottom: 12px; }

.HoursCard_hours_card__UPxMI button {
  color: #0076ff; }

.HoursCard_heading__1YLj5 {
  margin-bottom: 8px; }

.SimpleCard_simple_card__2dvpX {
  cursor: pointer;
  padding-top: 14px;
  padding-bottom: 14px;
}

.SimpleCard_simple_card__2dvpX img {
  top: 1px;
  width: 17px;
  float: left;
  height: 17px;
  position: relative;
  margin-right: 10px;
}

img.SimpleCard_alignRight__uHASN {
  float: right;
}

.SimpleCard_simple_card__2dvpX p {
  margin-bottom: 0;
}

.BackButton_back_button__3AOKO {
  font-size: 0.9em;
  padding-bottom: 14px; }

.BackButton_back_button__3AOKO img {
  top: -1px;
  width: 18px;
  height: 15px;
  margin-right: 8px;
  position: relative; }

.BackButton_back_button__3AOKO {
  color: #0076ff; }

.CategoryResultCard_button__FFJKH {
  width: 100%; }

.CategoryResultCard_wrapper__3vfAW {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 4.27px;
  box-shadow: 0 1px 2px 0 rgba(192, 189, 189, 0.5); }

.CategoryResultCard_image__1B1-0 {
  width: 100%;
  height: auto;
  padding: 10px; }

.CategoryResultCard_label__3xI_Q {
  display: block;
  margin-top: 7px;
  font-size: 0.9em; }

.LocationResultCard_card__1i31s {
  height: 81px;
  cursor: pointer;
  font-size: 0.9em;
  overflow: hidden; }

.LocationResultCard_meta__3G55s {
  display: -webkit-flex;
  display: flex;
  font-size: 0.9em;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.LocationResultCard_name__17Z8P {
  font-size: 0.9em;
  margin-bottom: 6px; }

.LocationResultCard_type__HOgfz {
  color: #666666; }

.LocationResultCard_short_name__19hS8 {
  color: #4a4a4a; }

.LocationResultCard_type__HOgfz,
.LocationResultCard_short_name__19hS8 {
  font-size: 0.9em;
  margin-bottom: 4px; }

.LocationResultCard_image_wrapper__1hwqP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.LocationResultCard_image_wrapper__1hwqP p:last-child {
  margin-bottom: 0; }

.LocationResultCard_badge__3Wx2E {
  color: #fff;
  min-width: 55px;
  font-size: 0.9em;
  padding: 2px 5px;
  text-align: center;
  border-radius: 12px; }

.LocationResultCard_open__2xW6J {
  background: #07c86c; }

.LocationResultCard_closed__328eb {
  background: #ff3d4d; }

.LocationResultCard_opening_soon__2KDs6 {
  background: #ec6634; }

.LocationResultCard_hours__2FL6z {
  display: -webkit-flex;
  display: flex;
  font-size: 0.8em;
  -webkit-align-items: self-end;
          align-items: self-end;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.LocationResultCard_time__1LNXt {
  font-size: 0.9em;
  margin-bottom: 0;
  text-align: right; }

.LocationResultCard_image__2lZ-D {
  width: 45px;
  height: 45px;
  padding: 5px;
  object-fit: cover;
  max-width: 55.33px; }

.LocationResultCard_image_bg___LqtS {
  margin-right: 8px;
  border-radius: 6px;
  background: #f2f2f2; }

.LocationsList_locations__list__2xN4Z {
  padding-left: 0;
  list-style: none;
  overflow-y: scroll; }

.LocationDetails_searchbox__dd9vk {
  margin-bottom: 15px; }

.LocationDetails_hr__2rjYj {
  margin: 0;
  border-top: 1px solid #f0f0f0; }

.LocationDetails_anchor__3y9kY,
.LocationDetails_anchor__3y9kY:hover {
  color: #212121; }

.SidebarLayout_container__4nk9K {
  height: calc(100vh - 10em);
  overflow-y: scroll; }

.SidebarLayout_sidebar__111MS {
  height: calc(100vh - 10em);
  padding: 15px;
  overflow-y: scroll; }

.SidebarLayout_fixed_container__30l4D {
  padding-left: 0; }

@media (max-width: 1199.98px) {
  .SidebarLayout_container__4nk9K {
    height: 100vh; }
  .SidebarLayout_sidebar__111MS {
    -webkit-order: 2;
            order: 2; }
  .SidebarLayout_fixed_container__30l4D {
    top: 0;
    z-index: 1;
    height: 20vh;
    position: -webkit-sticky;
    position: sticky;
    padding-left: 15px; } }

.LocationCategoriesList_categories__list__KhnJC {
  padding-left: 0;
  list-style: none;
  overflow-y: scroll; }

.LocationCategoriesList_categories__list__KhnJC li {
  margin-bottom: 12px; }

.CampusMapDining_searchbox__JLhMC {
  margin-bottom: 15px; }

.CampusMapDining_results__list__3VeUH {
  padding-left: 0;
  list-style: none;
  overflow-y: scroll; }

.CampusMapDining_results__list__3VeUH li {
  margin-bottom: 20px; }

.DiningList_locations__list__3zYAK {
  padding-left: 0;
  list-style: none;
  overflow-y: scroll; }

.DiningDetails_searchbox__Gd3bS {
  margin-bottom: 15px; }

.DiningDetails_hr__2egiR {
  margin: 0;
  border-top: 1px solid #f0f0f0; }

.DiningDetails_anchor__3tx93,
.DiningDetails_anchor__3tx93:hover {
  color: #212121; }

.personas__modal__meta__wrapper {
  position: relative;
  background: #0076ff; }

.personas__modal__meta {
  color: #fff;
  left: 30px;
  bottom: 30px;
  position: absolute; }

.personas__modal__title {
  font-size: 24px;
  margin-bottom: 2px; }

.personas__modal__copy {
  color: #bdbdbd; }

.personas__modal__buttons {
  margin: 0 auto;
  padding: 90px 0px; }

.personas__modal__buttons button {
  margin: 15px 0; }

.personas__modal__body {
  padding: 0; }

.tile {
}

.tile__body {
  padding: 0.85rem 0.7rem;
  padding-top: 0;
  padding-bottom: 0;
}

.tile__hero {
  width: 100%;
  height: 115px;
  object-fit: cover;
}

.tile__title_container {
  height: 48px;
  overflow: hidden;
}
.tile__title {
  height: 48px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
}

.campus_guide_page {
  margin-top: 30px; }

.card {
  margin-bottom: 15px; }

.welcome__message {
  margin-bottom: 40px; }

.welcome__message__btn {
  font-size: 1.25em;
  padding-bottom: 4px;
  border-bottom: 1px solid; }

.campus_guide_page main {
  margin-bottom: 15px; }

.campus_guide_page main strong {
  display: block;
  margin-bottom: 3px;
  color: #8e8e8e; }

.category__name {
  margin-bottom: 15px; }

.category__name p {
  text-transform: uppercase; }

.card:hover {
  cursor: pointer; }

.category {
  padding: 15px 0; }

.AppSnackbar_app_snackbar__19Yd- {
  opacity: 0;
  top: -100%;
  z-index: 1;
  width: 100%;
  position: fixed;
  text-align: center;
  transition: top 300ms ease-in-out, opacity 200ms ease-out;
}

.AppSnackbar_show__2FAWo {
  top: 105px;
  opacity: 1;
}

.AppSnackbar_default__W2H_I {
  color: #fff;
  background: #333333;
}

.AppSnackbar_danger__yEIRe {
  color: #fff;
  background: #f24858;
}

.AppSnackbar_wrapper__8yaOu {
  margin: 0 auto;
  max-width: 200px;
  font-size: 0.9em;
  border-radius: 8px;
  padding: 10px 16px;
  display: inline-block;
}

.breadcrumb {
  margin-bottom: 0;
  background: transparent; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '>'; }

.app_breadcrumb {
  margin: 10px 0;
  background: none; }

.app_breadcrumb__border {
  border-bottom: 1px solid #e9e9e9; }

.app_breadcrumb_link {
  text-transform: capitalize; }

.app_breadcrumb_item {
  color: #0076ff; }

.user__content {
  text-align: center;
}

.user__content__main {
  font-size: 2.25em;
  font-weight: 700;
}
@media (min-width: 460px) {
  .user__content__main {
    font-size: 3em;
  }
}

.user__content__icon i {
  font-size: 8em;
}

.user__reset {
  text-align: center;
}

.user__reset__main {
  font-size: 2.25em;
  font-weight: 700;
}
@media (min-width: 460px) {
  .user__reset__main {
    font-size: 3em;
  }
}

.user__reset__form {
  padding: 2em 0;
  text-align: start;
}

.user__reset__form label {
  font-weight: 700;
}

.user__reset__form input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
}

.user__reset__form input:disabled {
  background-color: transparent;
  background-color: initial;
}

.user__reset__footer button {
  width: 100%;
  max-width: 430px;
  padding: 0.5em 0;
  font-weight: 700;
  font-size: 1.25em;
}

.user__navbar {
  padding: 1em 0;
  margin: 0 1em 3em 1em;
  border-bottom: 1px solid #e0e0e0; }

.user__navbar__image {
  width: 4em;
  margin: auto;
  list-style-type: none; }

.user__navbar__image img {
  width: 100%;
  border-radius: 10px; }

