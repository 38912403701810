.app_snackbar {
  opacity: 0;
  top: -100%;
  z-index: 1;
  width: 100%;
  position: fixed;
  text-align: center;
  transition: top 300ms ease-in-out, opacity 200ms ease-out;
}

.show {
  top: 105px;
  opacity: 1;
}

.default {
  color: #fff;
  background: #333333;
}

.danger {
  color: #fff;
  background: #f24858;
}

.wrapper {
  margin: 0 auto;
  max-width: 200px;
  font-size: 0.9em;
  border-radius: 8px;
  padding: 10px 16px;
  display: inline-block;
}
