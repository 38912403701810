@import 'styles/variables';

.app_paragraph {
  white-space: pre-wrap;
}

.app_paragraph a {
  color: $color_primary;
  background-color: transparent;
  text-decoration: underline;
}
