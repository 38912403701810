@import 'styles/variables';

.announcement__header {
  width: 100%;
  height: 130px;
  position: relative;
  text-align: center;
  background: $color_accent;
}

.announcement__header img {
  top: 10px;
  position: relative;
}

.announcement__close {
  top: 14px;
  left: 10px;
  border: none;
  color: #ffffff;
  box-shadow: none;
  position: absolute;
  background: transparent;
}
