@import 'styles/variables';

.personas__modal__meta__wrapper {
  position: relative;
  background: $color_accent;
}

.personas__modal__meta {
  color: #fff;
  left: 30px;
  bottom: 30px;
  position: absolute;
}

.personas__modal__title {
  font-size: 24px;
  margin-bottom: 2px;
}

.personas__modal__copy {
  color: #bdbdbd;
}

.personas__modal__buttons {
  margin: 0 auto;
  padding: 90px 0px;
}

.personas__modal__buttons button {
  margin: 15px 0;
}

.personas__modal__body {
  padding: 0;
}
