.wrapper {
  display: flex;
  padding: 8px 14px;
  background: #fff;
  border-radius: 6px;
  justify-content: space-between;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.wrapper span {
  flex: 1;
}

input {
  border: none;
  color: #4d4d4d;
  width: calc(100% - 24px);

  &:focus,
  &:active {
    outline: none;
  }
}
