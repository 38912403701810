$height: calc(100vh - 10em);

.container {
  height: $height;
  overflow-y: scroll;
}

.sidebar {
  height: $height;
  padding: 15px;
  overflow-y: scroll;
}

.fixed_container {
  padding-left: 0;
}

@media (max-width: 1199.98px) {
  .container {
    height: 100vh;
  }

  .sidebar {
    order: 2;
  }

  .fixed_container {
    top: 0;
    z-index: 1;
    height: 20vh;
    position: sticky;
    padding-left: 15px;
  }
}
