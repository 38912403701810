$color_white: #ffffff;
$color_black: #212121;
$color_link: #0076ff;
$color_primary: #0076ff;
$color_primary_hover: #0085c4;
$color_accent: #0076ff;
$color_secondary: #8e8e8e;

$color_success: #6ec537;
$color_danger: #f5a623;
$color_warning: #ff0000;
$color_info: #17a2b8;
$color_offwhite: #e0e0e0;

$font_36px: 2.25em;
$font_20px: 1.25em;
$font_16px: 1em;
$font_small: 0.9em;

$family_bold: 'oohlala_bold';
$family_regular: 'oohlala_regular';

$weight_bold: 600;
$weight_regular: 400;
