.meta_card img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  margin-bottom: 12px;
}

.meta_card p {
  color: #333333;
}
