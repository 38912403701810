@import 'styles/variables';

.back_button {
  font-size: 0.9em;
  padding-bottom: 14px;
}

.back_button img {
  top: -1px;
  width: 18px;
  height: 15px;
  margin-right: 8px;
  position: relative;
}

.back_button {
  color: $color_primary;
}
