@import 'styles/variables';

.meta img {
  width: 720;
  height: 240;
}

.description {
  margin-top: 30px;
}

.page_event_host__name,
.page_event_host__avatar {
  display: inline-block;
}

.page_event_host__name {
  margin-left: 15px;
}

.page_event__hero__wrapper {
  height: 250px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.add_to_calendar {
  margin-bottom: 20px;
}

header {
  margin-bottom: 30px;
}

div.divider {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $color_offwhite;
}
