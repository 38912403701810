input.navbar__search {
  border: none;
  width: 100%;
}

.app_master_search_icon,
input.navbar__search::placeholder {
  color: #bdbdbd;
}

input.navbar__search:focus {
  box-shadow: none;
  border-color: transparent;
}
