@font-face {
  font-family: 'oohlala_bold';
  src: url('./Gibson-SemiBold.woff2') format('woff2'),
    url('./Gibson-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'oohlala_regular';
  src: url('./Gibson-Regular.woff2') format('woff2'),
    url('./Gibson-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
