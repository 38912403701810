.app_pagination {
  margin-top: 40px;
  text-align: center;
}

.app_pagination button {
  border: none;
  box-shadow: none;
  background: transparent;
}

.app_pagination button:not(:disabled):hover {
  cursor: pointer;
}

.app_pagination span {
  top: -6px;
  position: relative;
}
