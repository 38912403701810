@import 'styles/variables';

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 300ms ease-in-out;
}

.button--animate:hover {
  box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.3);
}

.button--info {
  color: $color_white;
  background: $color_info;
}

.button--primary {
  color: $color_white;
  background: $color_primary;
}

.button--accent {
  color: $color_white;
}

.button--danger {
  color: $color_white;
  background: $color_danger;
}

.button--warning {
  color: $color_white;
  background: $color_warning;
}

.button--success {
  color: $color_white;
  background: $color_success;
}

.button--transparent {
  color: $color_black;
  background: transparent;
}

.button--block {
  width: 100%;
  display: block;
}

.button--border {
  border: 1px solid;
}

.button--hover:hover {
  cursor: pointer;
}

.button--disabled {
  display: block;
}

.button--no-padding {
  padding: 0;
}

.button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  &:focus,
  &:active {
    outline: none;
  }
}
