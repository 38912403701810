@import 'styles/variables';

.carousel-indicators li {
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.carousel_img_wrapper {
  width: 100%;
  height: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.app_carousel_indicator {
  height: 35px;
  background: $color_black;
  margin-left: 0;
  margin-right: 0;
  bottom: 0;
  margin-bottom: 0;
}
