.button {
  width: 100%;
}

.wrapper {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 4.27px;
  box-shadow: 0 1px 2px 0 rgba(192, 189, 189, 0.5);
}

.image {
  width: 100%;
  height: auto;
  padding: 10px;
}

.label {
  display: block;
  margin-top: 7px;
  font-size: 0.9em;
}
