.user__reset {
  text-align: center;
}

.user__reset__main {
  font-size: 2.25em;
  font-weight: 700;
}
@media (min-width: 460px) {
  .user__reset__main {
    font-size: 3em;
  }
}

.user__reset__form {
  padding: 2em 0;
  text-align: start;
}

.user__reset__form label {
  font-weight: 700;
}

.user__reset__form input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
}

.user__reset__form input:disabled {
  background-color: initial;
}

.user__reset__footer button {
  width: 100%;
  max-width: 430px;
  padding: 0.5em 0;
  font-weight: 700;
  font-size: 1.25em;
}
