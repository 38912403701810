@import 'styles/variables';

.hours_card {
  padding-top: 14px;
}

.schedules {
  padding-left: 0;
  list-style: none;
  font-size: 0.96em;
}

.dining_list_item:not(:last-child) {
  margin-bottom: 12px;
}

.hours_card button {
  color: $color_primary;
}

.heading {
  margin-bottom: 8px;
}
