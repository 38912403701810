.tile {
}

.tile__body {
  padding: 0.85rem 0.7rem;
  padding-top: 0;
  padding-bottom: 0;
}

.tile__hero {
  width: 100%;
  height: 115px;
  object-fit: cover;
}

.tile__title_container {
  height: 48px;
  overflow: hidden;
}
.tile__title {
  height: 48px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
}
