.wrapper {
  text-align: center;
  margin: 70px auto 30px auto;
}

.img_wrapper {
  width: 120px;
  height: 120px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;

  img {
    width: 40px;
  }
}

.text {
  color: #d4d4d4;
  margin-top: 10px;
}
